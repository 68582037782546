.Terminal {
  padding: 20px 25px;
  background-color: #202020;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.4;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  flex: 1 1 auto;
}

.Terminal__scrollWrapper {
  overflow-y: auto;
  height: 100%;
}

.Terminal__input {
  color: #186ff2;
}

.Terminal__cursor {
  width: 1px;
  height: 1em;
  display: inline-block;
  position: relative;
}

.Terminal__cursor:before {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  top: 0.15em;
  width: 10px;
  height: 1.1em;
  background-color: #fff;
  opacity: 0.5;
}

.Terminal__helpCommand {
  color: rgb(238, 156, 52);
  display: inline-block;
}

.Terminal pre {
  margin: 0;
}

/* Light theme colors */

.Terminal.Terminal--light {
  background-color: #fff;
  color: #333;
}

.Terminal--light .Terminal__cursor:before {
  background-color: #333;
}
