.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App__header {
  padding: 20px 25px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: relative;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
}

.App__loadingLabel {
  text-align: center;
  margin-top: 50px;
}
