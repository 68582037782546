.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(54, 54, 54, 0.49);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper .modal {
  background: white;
  color: black;
  padding: 20px 20px 0;
  box-shadow: 3px 3px 10px black;
  width: 400px;
  position: relative;
  border-radius: 5px;
}

.modal-wrapper .close {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 36px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
}

.modal-wrapper .header {
  font-size: 18px;
  line-height: 36px;
  color: #222;
  background: #f0f0f0;
  padding: 0 10px;
}

.modal-wrapper .body {
  padding: 15px 0;
}

.modal-wrapper .body + .header {
  margin-top: 20px;
}

.modal-wrapper .item {
  min-height: 36px;
  line-height: 36px;
  color: #666;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-wrapper .item + .item {
  margin-top: 10px;
}

.modal-wrapper .error {
  color: brown;
  font-size: 14px;
  word-break: break-word;
  line-height: 1.6;
}

.modal-wrapper .required:after {
  content: "*";
  color: #e91e63;
  position: relative;
  top: -1px;
}

.modal-wrapper .input {
  width: 220px;
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px solid #aaa;
  background: white;
}

.modal-wrapper .input:focus {
  outline-color: #2196f3;
}

.modal-wrapper .checkbox-wrap {
  width: 220px;
}

.modal-wrapper .checkbox {
  appearance: none;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  user-select: none;
  flex-shrink: 0;
  height: 18px;
  width: 18px;
  color: #4299e1;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 0;
}

.modal-wrapper .checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.modal-wrapper .checkbox:focus {
  outline: 0;
}

.modal-wrapper .footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.btn {
  color: white;
  padding: 0 16px;
  height: 42px;
  line-height: 42px;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  background: none;
}

.btn.outline {
  color: #2196f3;
  border: 1px solid currentColor;
}

.btn.primary {
  background-color: #2196f3;
  color: white;
}
